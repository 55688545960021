
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader } from "../../components"
import { Grid, Typography, Divider, Breadcrumbs } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { COLORS } from "../../constants"
import { useSnackbar } from "notistack"
import { createPayment, makePayment, getPlans } from "../../api/subscription"
import { Link as MaterialUILink } from "@mui/material"
import { Link } from "react-router-dom"
import { DASHBOARD, SUBSCRIPTION } from "../../constants/routes"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"

export default function Checkout() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const [state, setState] = useState({
    loading: false,
    name: "",
    selected: null
  })

  const { loading, name, selected } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  useEffect(() => {
    _getPlans()
  }, [])

  const _getPlans = async () => {
    try {
      handleChange("loading", true)
      const res = await getPlans()
      handleChange("plans", res?.data?.results)
      if (res?.data?.results?.length > 0) {
        const selected = res?.data?.results?.find(e => e?.id === id)
        handleChange("selected", selected)
      }
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return
    }

    try {
      handleChange("loading", true)
      const cardElement = elements.getElement(CardElement)

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
        },
      })

      if (error) {
        enqueueSnackbar(`Error: ${error.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        handleChange("loading", false)
        return
      }

      const res = await makePayment({
        payment_method: paymentMethod.id,
        plan_id: id
      })      
      
      if (res?.status === 200) {
        enqueueSnackbar(`Payment has been submitted!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        handleChange("loading", false)
        navigate('/dashboard')
      } else {
        throw new Error("Payment failed")
      }

    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data || {})
      enqueueSnackbar(`Error: ${errorText[0] || error.message}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className="bg-white">
        <div className="divCenter loginContainer">
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} className="ml-5 mr-5">
              <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                <MaterialUILink underline="hover" color="inherit" to={DASHBOARD} component={Link}>
                  Home
                </MaterialUILink>
                <MaterialUILink underline="hover" color="inherit" to={SUBSCRIPTION} component={Link}>
                  Subscription
                </MaterialUILink>
                <Typography color="text.primary">Checkout</Typography>
              </Breadcrumbs>

              <div className="font-24 mb-4 font-bold">Checkout</div>
              <Divider />
              <Grid container spacing={2} className="mt-4 mb-4">
                <Grid item xs={6}>
                  <p className="font-14 mb-4">{selected?.description}</p>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="h6" className="checkboxLabel">
                      Card Details
                    </Typography>
                    <div className="cardBox">
                      <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
                    </div>
                    <Grid container spacing={2} sx={{ marginTop: -5 }}>
                      <Grid item xs={12} md={12}>
                        <AppInput
                          className="mb-4 mt-3"
                          value={name}
                          inputWidthFull
                          name={"name"}
                          onChange={handleChange}
                          placeholder={"Card Holder Name"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"}>
                <AppButton
                  width={350}
                  title={"Confirm and pay"}
                  onClick={handlePayment}
                  loading={loading}
                  disabled={!name || !stripe || !elements}
                  backgroundColor={COLORS.primary}
                  color={"#fff"}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  )
}
