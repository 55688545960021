export const alertSimplifiedError = (error, field) => {
  if (error.response?.status === 500) {
    alert('Please contact support team.');
    return;
  }
  if ([401].includes(error.response?.status)) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    alert('The session has expired, please login again.');
    window.location.reload();
    return;
  }
  const errorResponse = error.response && error.response.data;
  if (!errorResponse) {
    alert('Something went wrong, please try again later');
    return;
  }
  // if error message is 'subscription not active', then do nothing.
  if (errorResponse.error === 'subscription not active') return;
  alert(getErrors(errorResponse));
};

export const getSimplifiedError = (error, field) => {
  if (error.response?.status === 500) {
    return 'Please contact support team.';
  }
  if ([401].includes(error.response?.status)) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
    return 'The session has expired, please login again.'
  }
  const errorResponse = error.response && error.response.data;
  if (!errorResponse) {
    return 'Something went wrong, please try again later'
  }
  return getErrors(errorResponse) || Object.keys(errorResponse).map(
      (key) => ['detail', 'non_field_errors'].includes(key) ? errorResponse[key] : `${key}: ${errorResponse[key]}`
  ).join(', ');
};

const getError = (error) => {
  if (!Object.keys(error).length || typeof error === 'string') return error;
  let errors = [];
  if (Array.isArray(error) && error.length) {
    errors = [error[0].message, ...error];
  } else {
    Object.keys(error).forEach((e) => {
      return (errors = [...errors, error[e]]);
    });
  }

  return errors.filter((e) => e);
};

const getErrors = (errorResponse) => {
  // console.log(errorResponse);
  return typeof errorResponse.error === 'string' ? errorResponse.error: errorResponse.message;
};

// const getErrors = (errorResponse) => {
//   let errors = [];
//   console.log(errorResponse);
//   Object.keys(errorResponse).forEach((error) => {
//     return (errors = [...errors, ...getError(errorResponse[error])]);
//   });
//   return errors;
// };
