const BASE_URL = 'https://smart-workhorse-33965.botics.co/'
// const BASE_URL = 'https://67d0-119-154-209-106.ngrok-free.app/'





// const BASE_URL = 'http://localhost:8000/'  // TODO

export const defaultConfig = {
  API_URL: BASE_URL
}

export const App = {
  config: defaultConfig
}

window.env = window.env || defaultConfig
App.config = { ...window.env }

export const API_URL = () => App.config.API_URL
