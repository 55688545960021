// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useRef, useState } from "react"
import { AppButton, AppInput, Layout } from "../../components"
import { Grid, Divider, CircularProgress, Switch } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import { COLORS, DAYS } from "../../constants"
import { useSnackbar } from "notistack"
import Sample from "../../assets/images/sample.png"
import { ReactComponent as UploadIcon } from "../../assets/svg/upload.svg"
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg"
import { ReactComponent as AddIcon } from "../../assets/svg/add.svg"
import {
  deleteEmployee,
  getEmployee,
  getWorksitesDetail
} from "../../api/business"
import moment from "moment"
import { getSimplifiedError } from "../../utils/error"
import { updateWorksite } from "../../api/business"
import { createWorksite } from "../../api/business"
import { MultiSelect } from "react-multi-select-component"
import axios from "axios"
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api"

export default function AddWorksite({}) {
  const navigate = useNavigate()
  const {} = useContext(AppContext)
  const hiddenFileInput = useRef(null)
  const hiddenFileInput1 = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const [data, setData] = useState()

  const [state, setState] = useState({
    loading: false,
    name: "",
    location: "",
    description: "",
    notes: "",
    monthly_rates: "",
    clear_frequency_by_day: [],
    desired_time: "",
    number_of_workers_needed: "",
    supplies_needed: "",
    upload_instruction_video_link: "",
    contact_person_name: "",
    contact_phone_number: "",
    show_dtails: false,
    // profile_image:  '',
    logo: null,
    instruction_video: null,
    opened: false,
    desired_time_text: new Date(),
    openStart: false,
    validNumber: false,
    worksiteData: null
  })

  const {
    loading,
    worksiteData,
    name,
    location,
    description,
    notes,
    monthly_rates,
    clear_frequency_by_day,
    desired_time,
    number_of_workers_needed,
    supplies_needed,
    contact_person_name,
    contact_phone_number,
    show_dtails,
    logo,
    instruction_video,
    opened,
    desired_time_text,
    openStart,
    validNumber,
    upload_instruction_video_link
  } = state

  const [loc, setLocation] = useState("")
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [searchResults, setSearchResults] = useState([])
  const [load, setLoading] = useState(false)
  const searchRef = useRef("")
  const [autocomplete, setAutocomplete] = useState(null)
  const [address, setAddress] = useState("")
  const API_KEY = "AIzaSyDhXQVfE8lvio3CXPR2IZG-ouw-efk1LRQ"

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDhXQVfE8lvio3CXPR2IZG-ouw-efk1LRQ",
    libraries: ["places"]
  })

  const onLoad = autocompleteInstance => {
    setAutocomplete(autocompleteInstance)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        const latitude = place.geometry.location.lat()
        const longitude = place.geometry.location.lng()
        setAddress(place.formatted_address)
        setLatitude(latitude)
        setLongitude(longitude)
        setLocation(place.formatted_address)
        setState(pre => ({
          ...pre,
          ["location"]: place.formatted_address
        }))
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  const doSearch = () => {
    if (searchRef.current === "") {
      return
    }
    setLoading(true)
    const searchedString = searchRef.current

    axios
      .get(
        `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${searchedString}&key=${API_KEY}`
      )
      .then(res => {
        if (searchRef.current === searchedString) {
          console.log({ resp: res.data.results })
          setSearchResults(res.data.results)
        }
        setLoading(false)
      })
      .catch(err => {
        console.error("Error fetching data: ", err)
        setLoading(false)
      })
  }

  const handleLocationChange = (key, value) => {
    setLocation(value)
    searchRef.current = value
    doSearch()
  }
  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  useEffect(() => {
    if (id) {
      _getWorksite()
    }
  }, [])

  const _getWorksite = async () => {
    try {
      handleChange("loading", true)

      let token = localStorage.getItem("token")
      const res = await getWorksitesDetail(id, token)
      setData(res.data)

      handleChange("loading", false)
      console.log("res?.data", res?.data)
      handleChange("worksiteData", res?.data)
      handleChange("name", res?.data?.personal_information?.name)
      handleChange("location", res?.data?.personal_information?.location)
      handleChange("description", res?.data?.personal_information?.description)
      handleChange("notes", res?.data?.personal_information?.notes)
      handleChange(
        "clear_frequency_by_day",
        getOptions(res?.data?.personal_information?.clear_frequency_by_day)
      )
      handleChange(
        "desired_time",
        res?.data?.personal_information?.desired_time
      )
      handleLocationChange("location", res?.data?.address_line_one)
      handleChange(
        "supplies_needed",
        res?.data?.personal_information?.supplies_needed
      )
      handleChange(
        "number_of_workers_needed",
        res?.data?.personal_information?.number_of_workers_needed
      )
      handleChange(
        "contact_person_name",
        res?.data?.contact_person?.contact_person_name
      )
      handleChange(
        "contact_phone_number",
        res?.data?.contact_person?.contact_phone_number
      )
      handleChange("show_dtails", res?.data?.show_dtails)
      handleChange("logo", res?.data?.logo)
      handleChange("instruction_video", res?.data?.instruction_video)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getSimplifiedError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getValues = values => {
    const list = []
    values?.map(value => {
      list.push(value?.value)
    })
    return list
  }

  const getOptions = values => {
    const list = []
    if (Array.isArray(values)) {
      values?.map(value => {
        list.push({ label: value, value: value })
      })
    } else {
      list.push({ label: values, value: values })
    }
    return list
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      let token = localStorage.getItem("token")
      const formData = {
        worksite_information: {
          name,
          location,
          latitude,
          longitude,
          description,
          notes,
          monthly_rates,
          clear_frequency_by_day: getValues(clear_frequency_by_day),
          desired_time,
          number_of_workers_needed: number_of_workers_needed || "1",
          supplies_needed
        },
        contact_person: {
          contact_person_name,
          contact_phone_number
        },
        show_dtails
      }
      logo
        ? (formData.logo = logo.startsWith("data:") ? logo.split(",")[1] : logo)
        : (formData.logo = null)
      instruction_video
        ? (formData.instruction_video = instruction_video)
        : (formData.instruction_video = null)

      upload_instruction_video_link &&
        (formData.instruction_video = upload_instruction_video_link)
      if (worksiteData) {
        await updateWorksite(worksiteData?.id, formData, token)
        enqueueSnackbar(`Worksite has been updated!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      } else {
        await createWorksite(formData, token)
        enqueueSnackbar(`Worksite has been added!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
      handleChange("loading", false)
      navigate(-1)
    } catch (error) {
      const phoneError = error?.response.data?.error[0].replace(/[[\]']+/g, "")

      handleChange("loading", false)
      if (phoneError) {
        enqueueSnackbar(`${phoneError}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      } else
        enqueueSnackbar(getSimplifiedError(error), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
    }
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }
  const hiddenFileInputVideo = useRef(null)

  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)

  const handleChangeImage = event => {
    const file = event.target.files[0]
    getBase64(file, result => {
      handleChange("logo", result)
    })
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleRemoveImage = () => {
    setImage(null)
    handleChange("logo", null)
    setData(prevData => ({
      ...prevData,
      logo: null
    }))
  }

  const handleChangeVideo = event => {
    const file = event.target.files[0]
    getBase64(file, result => {
      handleChange("upload_instruction_video_link", result)
    })
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setVideo(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleRemoveVideo = () => {
    setVideo(null)
    handleChange("instruction_video", null)
    setData(prevData => ({
      ...prevData,
      instruction_video: null
    }))
  }

  const handleChangeImage1 = event => {
    const fileUploaded = event.target.files[0]
  }
  const [inputType, setInputType] = useState("text")

  const handleFocus = () => {
    setInputType("time")
  }

  const handleBlur = () => {
    if (!desired_time) {
      setInputType("text")
    }
  }

  return (
    <div>
      <Layout noFooter>
        <div className="adjustMaxWidth minheight80vh">
          <div className="headingrowBetween">
            <div>
              <div className="heading">
                {id ? "Edit Worksite" : "Add Worksite"}
              </div>
            </div>
          </div>
          <Divider className="mt-4" />
          {loading && (
            <div className="d-flex mt-4 justify-content-center">
              <CircularProgress />
            </div>
          )}
          <Grid
            container
            justifyContent={"space-between"}
            spacing={1}
            className="mt-4"
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <div className="heading font-18">
                    {"Worksite Information"}
                  </div>
                  <AppInput
                    value={name}
                    name={"name"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Worksite Name"}
                  />
                  <div>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <AppInput
                        value={loc}
                        name={"location"}
                        onChange={handleLocationChange}
                        className="mb-3 mt-3"
                        placeholder={"Worksite Location"}
                      />
                    </Autocomplete>
                    <ul>
                      {searchResults.map((result, index) => (
                        <li key={index}>{result.name}</li>
                      ))}
                    </ul>
                  </div>
                  <AppInput
                    value={description}
                    name={"description"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Description"}
                  />
                  <AppInput
                    value={notes}
                    name={"notes"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Notes"}
                  />
                  <AppInput
                    value={monthly_rates}
                    name={"monthly_rates"}
                    onChange={handleChange}
                    type={"number"}
                    className="mb-3 mt-3"
                    placeholder={"Monthly rate"}
                  />
                  <MultiSelect
                    options={DAYS}
                    value={clear_frequency_by_day}
                    overrideStrings={{
                      selectSomeItems: "Cleaning frequency by day"
                    }}
                    onChange={props =>
                      handleChange("clear_frequency_by_day", props)
                    }
                    className="dropdown"
                    labelledBy="Cleaning frequency by day"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className="heading font-18">{"Address information"}</div>
                  <AppInput
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type={inputType}
                    value={desired_time}
                    name="desired_time"
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder="scheduled start time"
                  />

                  <AppInput
                    value={number_of_workers_needed}
                    name={"number_of_workers_needed"}
                    type={"number"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Number of workers needed"}
                  />
                  <AppInput
                    value={supplies_needed}
                    name={"supplies_needed"}
                    onChange={handleChange}
                    type={"number"}
                    className="mb-5 mt-3"
                    placeholder={"Supplies needed"}
                  />
                  <Grid container justifyContent={"space-between"}>
                    <div className="heading font-18">{"Contact Person"}</div>
                    <div>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        className="mr-2"
                        onClick={() =>
                          handleChange("show_dtails", !show_dtails)
                        }
                        value={show_dtails}
                      />
                      <span>Show details</span>
                    </div>
                  </Grid>
                  <AppInput
                    value={contact_person_name}
                    name={"contact_person_name"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Name"}
                  />
                  <AppInput
                    value={contact_phone_number}
                    name={"contact_phone_number"}
                    type={"number"}
                    onChange={handleChange}
                    className="mb-3 mt-3"
                    placeholder={"Phone Number"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mt-5">
                <Grid item md={6} lg={4} xs={12}>
                  <AppButton
                    title={"Upload worksite logo"}
                    onClick={() => hiddenFileInput.current.click()}
                    borderColor={COLORS.primary}
                    prefix={<UploadIcon className="mr-4" />}
                    borderRadius={12}
                    color={COLORS.primary}
                  />
                  <input
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    ref={hiddenFileInput}
                    onChange={handleChangeImage}
                    style={{ display: "none" }}
                  />
                  {image && (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid rgb(6, 114, 106)",
                        padding: 10,
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 10
                      }}
                    >
                      <img
                        src={image}
                        alt="Uploaded"
                        style={{
                          maxWidth: "20%",
                          height: "80px",
                          borderRadius: "12px"
                        }}
                      />
                      <button
                        onClick={handleRemoveImage}
                        style={{
                          padding: "10px 20px",
                          borderColor: COLORS.primary,
                          borderRadius: "12px",
                          color: COLORS.primary,
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                  {data?.logo && !image && (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid rgb(6, 114, 106)",
                        padding: 10,
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 10
                      }}
                    >
                      <img
                        src={data?.logo}
                        alt="Uploaded"
                        style={{
                          maxWidth: "20%",
                          height: "80px",
                          borderRadius: "12px",
                          cursor: "pointer"
                        }}
                        onClick={() => window.open(data?.logo, "_blank")}
                      />
                      <button
                        onClick={handleRemoveImage}
                        style={{
                          padding: "10px 20px",
                          borderColor: COLORS.primary,
                          borderRadius: "12px",
                          color: COLORS.primary,
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </Grid>
                <Grid item md={6} lg={4} xs={12}>
                  <AppButton
                    title={"Upload instruction video"}
                    onClick={() => hiddenFileInputVideo.current.click()}
                    borderColor={COLORS.primary}
                    prefix={<UploadIcon className="mr-4" />}
                    borderRadius={12}
                    color={COLORS.primary}
                  />

                  <input
                    type="file"
                    accept="video/*"
                    ref={hiddenFileInputVideo}
                    onChange={handleChangeVideo}
                    style={{ display: "none" }}
                  />

                  {video ? (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid rgb(6, 114, 106)",
                        padding: 10,
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 10
                      }}
                    >
                      <video
                        controls
                        style={{
                          maxWidth: "200px",
                          height: "80px",
                          borderRadius: "12px"
                        }}
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          marginTop: "10px",
                          padding: "10px 20px",
                          borderColor: COLORS.primary,
                          borderRadius: "12px",
                          color: COLORS.primary,
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    !data?.instruction_video && (
                      <p
                        style={{ color: COLORS.darkBlack, textAlign: "center" }}
                      >
                        Select a video less than 100MB
                      </p>
                    )
                  )}
                  {data?.instruction_video && !video && (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid rgb(6, 114, 106)",
                        padding: 10,
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 10
                      }}
                    >
                      <video
                        controls
                        style={{
                          maxWidth: "200px",
                          height: "80px",
                          borderRadius: "12px",
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          window.open(data?.instruction_video, "_blank")
                        }
                      >
                        <source
                          src={data?.instruction_video}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          marginTop: "10px",
                          padding: "10px 20px",
                          borderColor: COLORS.primary,
                          borderRadius: "12px",
                          color: COLORS.primary,
                          backgroundColor: "white",
                          cursor: "pointer"
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </Grid>

                <Grid item md={6} lg={4} xs={12}>
                  <AppButton
                    title={"Save"}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={
                      !name ||
                      !location ||
                      clear_frequency_by_day.length === 0 ||
                      !desired_time ||
                      !contact_person_name ||
                      !contact_phone_number
                    }
                    backgroundColor={COLORS.primary}
                    borderRadius={12}
                    color={COLORS.white}
                  />
                </Grid>
                {/* <Grid item md={6} lg={3} xs={12}>
                  <AppButton
                    title={"Edit"}
                    // onClick={handleSubmit}
                    borderColor={COLORS.primary}
                    prefix={<EditIcon className="mr-4" />}
                    borderRadius={12}
                    color={COLORS.primary}
                  />
                </Grid>
                <Grid item md={6} lg={3} xs={12}>
                  <AppButton
                    title={"Create a task"}
                    // onClick={handleSubmit}
                    borderColor={COLORS.primary}
                    prefix={<AddIcon className="mr-4" />}
                    borderRadius={12}
                    color={COLORS.primary}
                  />
                </Grid> */}
              </Grid>

              {/* <Grid
                container
                spacing={3}
                justifyContent={"flex-end"}
                className="mt-5"
              >
                <Grid item md={6} lg={4} xs={12}>
                  <AppButton
                    title={"Save"}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={
                      !name ||
                      !location ||
                      clear_frequency_by_day.length === 0 ||
                      !desired_time ||
                      !contact_person_name ||
                      !contact_phone_number
                    }
                    backgroundColor={COLORS.primary}
                    borderRadius={12}
                    color={COLORS.white}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
